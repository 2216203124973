<template>
  <div class="topBanner" v-if="datas.play" :style="{backgroundImage:'linear-gradient(to right,'+ (datas.contentBanner && datas.contentBanner.bgColor && datas.contentBanner.bgColor[0]) +','+  (datas.contentBanner && datas.contentBanner.bgColor && datas.contentBanner.bgColor[1]) +')'}">
    <img :src="datas.url" alt="">
    <img v-if="isContentBanner" :src="datas.contentBannerImg" alt="">
    <div :class="{'active': isContentBanner}">
      <div class="content ar">
        <h3>{{ datas.gameName }}</h3>
        <p
          v-for="(item,index) in datas.texts"
          :key="'topBanner_'+index">
          {{ item }}
        </p>
        <div v-if="datas.play.type == 'app'">
          <a 
            v-if="datas.play.googlePlay"
            :href="datas.play.googlePlay || 'javascript:;'"
            target="_blank">
            <img src="../../assets/img/google.png" alt="">
          </a>
          <a 
            v-if="datas.play.appStore"
            :href="datas.play.appStore || 'javascript:;'"
            target="_blank">
            <img src="../../assets/img/app store.png" alt="">
          </a>
        </div>
        <div v-if="datas.play.type == 'h5' && datas.play.url">
          <a class="h5Href" :href="datas.play.url">
            {{ langCommon.website }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {

    }
  },
  computed: {
    ...Vuex.mapGetters([
      "langCommon",
    ]),
    logoUrl(){
      let url = require('@/assets/img/logo/logo-white.png');
      if(this.headerColor==1){
        url = require('@/assets/img/logo/logo-black.png')
      }
      return url;
    }
  },
  props: [ "datas", "isContentBanner" ],
}
</script>
<style lang="stylus" scoped>
.topBanner{
  position: relative;
  & >img {
    width: 100%;  
  }
  & > div{
    position: absolute;
    top: 0rem;
    max-width: 45vw;
    // width: 40%;
    right: 4vw;
    height: calc( 100%);
    flex(row,nowrap,left,center);
    &.active{
       height: calc( 50% ); 
    }
  }
  .content{
    font-size: $Fts2;
    color: $Cf;
    background-color: rgba(0,0,0,.5);
    padding: .4rem;
    box-sizing: border-box;
    border-radius(.08rem);
    & h3 {
      font-size: $Fts1;
      &:after{
        content: "";
        display: block;
        width: .3rem;
        height: .06rem;
        background-color: $CLGy1;  
        margin: .04rem 0 .1rem -.06rem;
      }
    }
    & p {
      line-height: .32rem;
    }
    & a img{
       width: 1.9rem;
       margin: .1rem .2rem 0 0;
    }
    & .h5Href{
      color: $Cf;
      display: block;
      margin: .6rem auto 0;
      width: 90%;
      box-sizing: border-box;
      height: .4rem;
      line-height: .4rem;
      text-align: center;
      border: .01rem solid $CLGy1;
    }
  }
}

@media screen and (max-width: 1100px){
  .topBanner{
    & > div {
      position: static;  
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      & .content{
        border-radius(0);
        & a img{
          width: 1.5rem;
          margin: .14rem .14rem 0 0;
        }
      }
    }
  }
}
// @media screen and (max-width: 980px){
//   .topBanner{
//     & > div {
//       position: static;  
//       width: 100%;
//       max-width: 100%;
//       box-sizing: border-box;
//       & .content{
//         border-radius(0);
//       }
//     }
//   }
// }
</style>