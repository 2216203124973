<template>
  <div 
    v-if="datas && (datas.facebook || datas.tweet || datas.youtube || datas.ins)" 
    class="gameShare" 
    :style="{'backgroundColor':datas.bgColor}">
    <img class="left" v-if="datas.left && !screen" :src="datas.left" alt="">
    <div>
      <a v-show="datas.facebook" target="_blank" :href="datas.facebook || 'javascript:;'"></a>
      <a v-show="datas.tweet" target="_blank" :href="datas.tweet || 'javascript:;'"></a>
      <a v-show="datas.youtube" target="_blank" :href="datas.youtube || 'javascript:;'"></a>
      <a v-show="datas.ins" target="_blank" :href="datas.ins || 'javascript:;'"></a>
    </div>
    <img class="right" v-if="datas.right && !screen" :src="datas.right" alt="">
  </div>
</template>
<script>
export default {
  props: [ "datas" ],
  computed: {
    ...Vuex.mapGetters([
      "screen",
    ]),
  },
}
</script>
<style lang="stylus" scoped>
.gameShare{
  height: .7rem;
  text-align: center;
  position: relative;
  flex(row, nowrap, center, center);
}
.left,.right{
  position: absolute;  
  left: 0;
  bottom: 0;
  width: 1.16rem;  
}
.right{
  left: auto;
  right: 0;
  width: 1.32rem;  
}
a{
  display: inline-block;
  width: .4rem;
  height: .4rem;
  background-size: 100%;
  background-position: center center;
  margin: 0 3vw;
  &:nth-child(1){
    background-image: url("../../assets/img/fb.png");
  }
  &:nth-child(2){
    background-image: url("../../assets/img/tw.png");
  }
  &:nth-child(3){
    width: .47rem;
    background-image: url("../../assets/img/youtube.png");
  }
  &:nth-child(4){
    background-image: url("../../assets/img/ins.png");
  }
}


@media screen and (max-width: 980px){
  .gameShare{
    & a{
      margin: 0 2vw;
    }
  }
}
</style>