<template>
  <div 
    class="contentBanner"
    :style="bgImg">
    <div class="imgContent" v-if="datas.type == 'img'">
      <img :src="datas.img" alt="">
    </div>
    <div class="rollContent" v-if="datas.type == 'roll'">
      <div class="ornament" :style="{backgroundImage:'url('+ datas.ornament +')'}"></div>
      <div>
        <div class="swiper-container" ref="mySwiper">
          <div class="swiper-wrapper">
            <div 
              class="swiper-slide"
              v-for="(item,index) in datas.banner"
              :key="'contentBanner_'+index">
                <img :src="item" alt="">
            </div>
          </div>
        </div>
        <div 
          :class="['swiper-pagination',{'active':datas.page},datas.page]"
          ref="pagination">
        </div>
        <div class="swiper-button-prev">
          <img v-if="datas.left" :src="datas.left" alt="">
        </div>
        <div class="swiper-button-next">
          <img class="right" v-if="datas.left" :src="datas.left" alt="">
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      bgImg: {
        backgroundColor: "",
        backgroundImage: "",
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper
    }
  },
  props: [ "datas" ],
  watch:{
    datas(n,o){
      this.init();
    }
  },
  mounted(){
    if(this.datas){
      this.init();
    }
    setTimeout(()=>{
      var swiper = new Swiper(this.swiper, {
        loop: true,
        speed: 600,
        slidesPerView: 1,
        centeredSlides: true,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }); 
    },3000)
    
  },
  methods: {
    init(){
      console.log(this.datas)
      if(!this.datas)return;
      if(this.datas.bgColor || this.datas.bgImg){
        if(this.datas.bgColor){
          if(this.datas.bgColor.length == 1){
            this.bgImg["backgroundColor"] = this.datas.bgColor[0];
          }else{
            this.bgImg["backgroundImage"] = 'linear-gradient(to right,'+ this.datas.bgColor[0] +','+  this.datas.bgColor[1] +')';
          }
        }
        if(this.datas.bgImg){
          this.bgImg["backgroundImage"] = 'url('+ this.datas.bgImg +')';
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.contentBanner{
  background-size: 100%;
  position: relative;
  margin-bottom:-.01rem;
  background-size: cover;
}
.ornament{
  position: absolute;
  width: 61% !important;
  left: 19.5%;
  top: 12vw;
  z-index: 10;
  height: calc(100% - 34vw) !important;
  background-size: 100%;
}
.imgContent img{
  width: 100%;
}
.rollContent{
  height: 55vw;
  & > div {
    width: 56%; 
    margin: 0 auto ;
    padding-top: 14vw;
  }
}

</style>
<style lang="stylus">
.contentBanner{
  .swiper-container{
    width: 100%;
    // height: 
  }
  .swiper-slide{
    width: 100% !important;  
  }
  .swiper-slide img{
    width: 100% !important;  
  }
  .swiper-pagination{
    width: 100%;  
    margin-top: .2rem;
    & span{
      outline: none;  
    }
    &.active{
      & .swiper-pagination-bullet{
        width: 3vw;
        height: 3vw;
        background-size: 100%;
        background-color: transparent;
        opacity: .4;
        cursor: pointer;
      }
      & .swiper-pagination-bullet-active{
        opacity: 1;
      }
      &.enw span{
        background-image: url(../../../assets/img/page/point.png);
      }
      // &.ft span{
      //   background-image: url(../../../assets/img/enw/point.png);
      // }
    }
  }
  .swiper-pagination-bullet{
    width: .64rem;
    height: .06rem;
    border-radius(.01rem);
    background-color: $Cf;
    margin: 1.6vw 1.2vw; 
    opacity: 1;
  }
  .swiper-pagination-bullet-active{
    background-color: $CLGy1;
  }
  .swiper-button-prev,.swiper-button-next{
    width: 10%;
    outline: none;
    &:after{
      content: "";  
      width: 0;
      height: 0;
    }
    & img{
      width: 70%;  
    }
  }
  .swiper-button-prev, .swiper-container-rtl .swiper-button-next{
    left: 6%;  
    top: 55%;
  }
  .swiper-button-next, .swiper-container-rtl .swiper-button-prev{
    right: 6%;  
    top: 55%;
    & img{      
      tranform(rotate(180deg));  
    }
  }
}
@media screen and (max-width: 980px){
  .contentBanner{
    margin-top: 0;
    .swiper-pagination-bullet{
      margin: 1vw 1.2vw;
      width: .24rem;
      height: .04rem;
    } 
    .swiper-pagination{
      margin-top: .12rem;
    }
    .ornament{
      height: calc(100% - 37.4vw);
    }
  }
}
</style>